.zz[data-v-f410da70] {
  width: 100%;
  height: calc(100% - 50px);
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0);
  z-index: 999;
  overflow-y: auto;
  margin-top: 50px;
}